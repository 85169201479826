const translation = {
  fr: {
    filter_slogan_firstP: "BIENVENUE SUR LE SITE DE LA BRASSERIE LORRAINE",
    filter_slogan_secondP: "QUI DIT MIEUX ?",
    filter_indication_default: "Inscrivez votre date de naissance",
    filter_indication_error:
      "Désolé, vous devez être majeur(e) pour accèder à ce site",
    filter_enter_button: "ENTRER",
    menu_title_accueil: "ACCUEIL",
    menu_title_adn: "NOTRE ADN",
    menu_title_bieres: "NOS PRODUITS",
    menu_title_funzone: "FUN ZONE",
    menu_title_histoire: "NOTRE HISTOIRE",
    menu_title_valeurs: "NOS VALEURS",
    player_manifesto_src: "https://www.youtube.com/embed/Ka1T8AYGNIM",
    player_film_src: "https://www.youtube.com/embed/Ka1T8AYGNIM",
    player_iframe_src:
      "https://www.vip-studio360.fr/galerie360/visites/vv-brasserie-lorraine/vv-brasserie-lorraine-c.html",
    mood_title: "#LEGOÛTDELINSTANT*",
    mood_note:
      "*Brassée depuis 1922 en Martinique, la bière Lorraine fait la fierté de son île avec ses nombreuses médailles obtenues au concours international Monde Sélection. Fine, légère, et rafraîchissante, la bière Lorraine est adaptée à tous les instants de la vie en Martinique.",
    adn_title: '<span className="ADN-title-font yellow w900">NOTRE</span> ADN',
    adn_videos_valeurs_src:
      "https://www.youtube.com/embed/YtTYJ2iJssA?autoplay=1&amp;mute=1&playlist=YtTYJ2iJssA&amp;loop=1",
    adn_videos_film_src:
      "https://www.youtube.com/embed/YtTYJ2iJssA?autoplay=1&amp;mute=1&playlist=odfe1oTW7rA&ab&amp;loop=1",
    adn_videos_valeurs_title: "NOTRE HISTOIRE",
    adn_videos_film_title: "MÉDAILLE D'OR 2024",
    adn_videos_visite_title: "VISITE VIRTUELLE",
    adn_videos_format: "3",
    bieres_carac1_title: "PROFIL",
    bieres_carac2_title: "ARÔME",
    bieres_carac3_title: "ROBE",
    bieres_carac4_title: "LE +",
    funzone_title_yellow: "GRAND JEU DES 100 ANS",
    funzone_title_white: "Remporte ton coffret collector Lorraine",
    funzone_fillform: "Remplis le formulaire pour participer :",
    funzone_prenom_label: "Prénom",
    funzone_nom_label: "Nom",
    funzone_email_label: "Email",
    funzone_tel_label: "Téléphone",
    funzone_sumbit_button: "PARTICIPER",
    funzone_rules: "Voir le règlement du jeu",
    funzone_details:
      'Pour plus de détails sur l’utilisation de vos données personnelles, consultez notre <u><NavLink to="politique-de-confidentialité" exact>politique de confidentialité</NavLink></u>.',
    histoire_title:
      '<span className="Histoires-title-font yellow">NOTRE</span> HISTOIRE',
    engage_title: '<span className="yellow w900">UNE MARQUE</span> ENGAGÉE',
    engage_P1:
      "« Consommer responsable », tel est le mot d’ordre de la marque Lorraine, consciente des enjeux d’une consommation raisonnée et responsable de ses produits. Totalement impliquée dans cette démarche citoyenne, elle soutient les initiatives des différents acteurs martiniquais dans leurs plans de prévention contre l’alcoolisme et les addictions.",
    engage_P2:
      "Nos ingrédients sont sélectionnés avec soin pour assurer une qualité optimale et une parfaite régularité. Les malts, les houblons, les levures… répondent à un cahier des charges très précis. Chaque ingrédient est testé et évalué à sa réception. Le savoir-faire local, plusieurs fois récompensé internationalement, est la garantie d’une qualité constante et irréprochable de la bière Lorraine. Les médailles obtenues au concours Monde Sélection témoignent de cette qualité et de cette régularité.",
    engage_P3:
      "La réduction de la consommation électrique constitue un axe de travail permanent: La Brasserie Lorraine est équipée de 5 000 m² de panneaux photovoltaïques, qui permettent de réduire significativement l’utilisation d’énergies fossiles. Cette centrale photovoltaïque a une puissance totale d’un demi-mégawatt (510 kw) et a  produit depuis sa construction (en 2012) 7 gigawatts d’énergie verte. Cela représente environ 23% de la consommation électrique de la Brasserie Lorraine. Par ailleurs, les équipes de la Brasserie travaillent en continu sur les économies d’énergie.",
    engage_P4:
      "Le recyclage des bouteilles, via sa gamme de produits consignés, représente toujours une part conséquente de l’activité permettant d’économiser jusqu’à 200 tonnes de verre par an.",
    reuse_title:
      '<span className="Reuse-title-font w900 yellow">LE RECYCLAGE DES DRÊCHES</span><br />DE BRASSERIE LORRAINE, EN MARTINIQUE',
    reuse_stat_left:
      '<span className="Reuse-stat-big w900">1100 TONNES</span> de drêches recyclées chaque année.',
    reuse_stat_right:
      'Environ <span className="Reuse-stat-big w900"> 200 TONNES</span> de verre sont ainsi économisées chaque année.',
    reuse_creation:
      'Le principe du verre consigné, les fameuses “caisses jaunes Lorraine",<br /><span className="Reuse-creation-big w900 yellow">EXISTE DEPUIS PLUS DE 50 ANS</span><br />en Martinique.',
    social_fb_link: "https://www.facebook.com/blorraine972",
    social_insta_link: "https://www.instagram.com/lorraine_ice",
    social_confidentialite:
      '<NavLink to="/politique-de-confidentialité" exact>POLITIQUE DE CONFIDENTIALITÉ</NavLink>',
    social_mentions_legales:
      '<NavLink to="/mentions-legales" exact>MENTIONS LÉGALES</NavLink>',
    social_cookies:
      '<NavLink to="/politique-de-cookies" exact>POLITIQUE DE COOKIES</NavLink>',
    contact_trigger: "CONTACTEZ-NOUS",
    contact_title: "Vous pouvez nous contacter à l'adresse ci-dessous !",
    contact_P1: "BRASSERIE LORRAINE",
    contact_P2: "HABITATION L’UNION 97232 LE LAMENTIN",
    warning_footer:
      "L’abus d’alcool est dangereux pour la santé, à consommer avec modération",
  },
};

export default translation;
