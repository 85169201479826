export const bieresData = [
  {
    image: '/assets/lorraine-blonde.png',
    name: 'LORRAINE BLONDE',
    name_yellow: 'LORRAINE ',
    name_white: 'BLONDE',
    profil: 'Bière blonde lager à 5°. Très rafraîchissante, caractérisée par une subtile amertume.',
    arome: 'Arômes doux de malts, et subtiles notes fruitées.',
    robe: 'Blond pâle et limpide. Mousse aérienne.',
    leplus: 'Recette centenaire, inchangée et toujours aussi rafraîchissante !',
    id: 1,
  },
  {
    image: '/assets/lorraine-ice.png',
    name: 'LORRAINE ICE',
    name_yellow: 'LORRAINE ',
    name_white: 'ICE',
    profil: 'Bière blonde à 5,4°, sans amertume. Douce et légère.',
    arome: 'Subtiles notes d’agrumes en fin de bouche, apportées par la variété de houblon séléctionée.',
    robe: 'Blond pâle aux reflets dorés. Mousse fine et délicate.',
    leplus: 'Bière sans amertume. EBU (European Bitterness Unit) = 10.',
    id: 2,
  },
  {
    image: '/assets/lorraine-ambree.png',
    name: 'LORRAINE AMBRÉE',
    name_yellow: 'LORRAINE ',
    name_white: 'AMBRÉE',
    profil: 'Bière ambrée à 5°, ronde et gourmande , au caractère bien affirmé !',
    arome: 'Notes de caramel, apportées par la variété de malt sélectionnée.',
    robe: 'Dorée et éclatante. Mousse épaisse et persistante.',
    leplus: 'De belles notes sucrées, aussi surprenantes que douces.',
    id: 3,
  },
  {
    image: '/assets/malta-lorraine.png',
    name: 'MALTA LORRAINE',
    name_yellow: 'MALTA ',
    name_white: 'LORRAINE',
    profil: 'Boisson maltée rafraîchissante sans alcool.',
    arome: 'Subtiles notes de caramel et de chocolat, apportées par la variété de malt sélectionnée.',
    robe: 'Sombre aux reflets intenses. Mousse fine et discrète.',
    leplus: 'Apport de vitamines quotidien. Teneur garantie en vitamine B6',
    id: 4,
  },
  {
    image: '/assets/lorraine-gingembre.png',
    name: 'Malta Lorraine Gingembre',
    name_yellow: 'MALTA ',
    name_white: 'GINGEMBRE',
    profil: 'Boisson maltée sans alcool aromatisée au gingembre',
    arome: "Subtiles notes acidulées et épicées apportées par l'extrait de gingembre sélectionné.",
    robe: 'Brune aux reflets intenses. Mousse fine et vivifiante.',
    leplus: "Apport de fraîcheur et d'énergie. Teneur garantie en vitamine B6.",
    id: 5,
  },
];
