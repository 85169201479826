export const histoiresData = [
  {
    id: 1,
    urlImg: '/assets/histoire-1.jpg',
    alt: 'Création de la brasserie Lorraine en 1922',
    date: 1922,
    description: 'Brassée pour la première fois en 1922, la bière Lorraine doit son nom à son fondateur René de Jaham qui, après des études de brassage en Lorraine, décide de créer la marque du même nom sur le territoire Martiniquais. La construction de la Brasserie Lorraine a pris du temps, implantée au quartier Pont Damas à Fort-de-France.',
  },
  {
    id: 2,
    urlImg: '/assets/histoire-2.jpg',
    alt: 'Assitance technique grâce à Heineken en 1958',
    date: 1958,
    description: "En 1958, la Brasserie Lorraine passe une convention d’assistance technique avec Heineken, afin d'améliorer ses compétences et son savoir-faire. Tout en gardant sa formule de fabrication unique et son caractère propre, la bière Lorraine a pu ainsi bénéficier de progrès technologiques garantissant à ses consommateurs une qualité de niveau international.",
  },
  {
    id: 3,
    urlImg: '/assets/histoire-3.jpg',
    alt: "Médaille d'argent au olympiades de la bière en 1961",
    date: 1961,
    description: "En 1961, la bière Lorraine obtient sa première médaille au concours international Monde Sélection de Bruxelles. Il s'agit d'une médaille d'argent. Cette récompense est la reconnaissance internationale d'un savoir-faire local.",
  },
  {
    id: 4,
    urlImg: '/assets/histoire-4.jpg',
    alt: "Dans les années 60, les camionnettes de livraison Lorraine sillonnent la Martinique, rendant la marque accessible sur l'ensemble de l'île.",
    date: 1963,
    description: "Dans les années 60, les camionnettes de livraison Lorraine sillonnent la Martinique, rendant la marque accessible sur l'ensemble de l'île.",
  },
  {
    id: 5,
    urlImg: '/assets/histoire-5.jpg',
    alt: 'La ligne d’embouteillage à Fort-de-France. A l’époque, la bouteille consignée représentait la majeure partie des ventes',
    date: 1969,
    description: 'La ligne d’embouteillage à Fort-de-France. À l’époque, la bouteille consignée représentait la majeure partie des ventes.',
  },
  {
    id: 6,
    urlImg: '/assets/histoire-6.jpg',
    alt: 'En 1970, la brasserie déménage au Lamentin, sur la route du Robert doublant quasiment sa capacité de production',
    date: 1970,
    description: 'En 1970, la brasserie déménage au Lamentin, sur la route du Robert doublant quasiment sa capacité qui passe de 35.000 hectolitres par an à 60.000 hectolitres.',
  },
  {
    id: 7,
    urlImg: '/assets/histoire-7.jpg',
    alt: 'Dans les années 80, la surface de production s’agrandit, afin de répondre à la demande croissante du marché Martiniquais',
    date: 1985,
    description: 'Dans les années 80, la surface de production s’agrandit, afin de répondre à la demande croissante du marché Martiniquais.',
  },
  {
    id: 8,
    urlImg: '/assets/histoire-8.jpg',
    alt: 'En 2014, le Groupe Antilles-Glaces rachète la Brasserie Lorraine',
    date: 2014,
    description: "En 2014, le Groupe Antilles-Glaces rachète la Brasserie Lorraine, et entame un travail sur les fondamentaux : régularité de la recette, nouvelle communication, rénovation de l'outil de production.",
  },
  {
    id: 9,
    urlImg: '/assets/histoire-9.jpg',
    alt: 'La rénovation de l’outil industriel sera finalisée en 2020',
    date: 2020,
    description: 'La rénovation de l’outil industriel sera finalisée en 2020. Une nouvelle ligne d’embouteillage sera installée, permettant de doubler la capacité de production tout en assurant des contrôles qualité renforcés.',
  },
];
